import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Source } from '@coco/types/deliveries';
import { CDN_URL, Colors, Typography } from '@coco/ui-web';

import { EVENTS, ListDelivery } from 'src/@types';
import { COMMON_KEYS, LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { trackSegment } from 'src/lib/segment';
import { LATE_LOAD_ID } from 'src/lib/toast';
import { hasBotSerial } from 'src/lib/utils';
import { useOrderStore } from 'src/stores/orders';

interface Props {
  closeToast?: () => void; // injected by react-toastify
  repeatAudioCount: number;
  lateLoadedDeliveries: ListDelivery[];
}

const Container = styled.div({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(250, 16, 16, 0.7)',
  cursor: 'pointer',
  padding: '24px',
});

const Body = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Title = styled.div({
  color: Colors.white,
  marginBottom: '20px',
  textAlign: 'center',
});

const CircleContainer = styled.div({
  width: '360px',
  height: '360px',
  borderRadius: '50%',
  backgroundColor: Colors.white,
  marginBottom: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-height: 675px)': {
    width: '240px',
    height: '240px',
  },
  '@media (max-width: 540px)': {
    width: '300px',
    height: '300px',
  },
});

const Count = styled.div({
  color: Colors.grey900,
  marginBottom: '24px',
});

const OrderCopy = styled.div({
  color: Colors.grey900,
});

const Caption = styled.div({
  color: Colors.grey900,
  marginBottom: '40px',
  textAlign: 'center',
});

const TapAnywhere = styled.div({
  color: Colors.white,
});

export const LateLoadedOrder = ({ closeToast, repeatAudioCount = 1, lateLoadedDeliveries }: Props) => {
  const { t: commonT } = useTranslation(LOC_NS.COMMON);
  const { t: ordersT } = useTranslation(LOC_NS.ORDERS);
  const [audio] = useState(
    typeof Audio !== 'undefined' ? new Audio(`${CDN_URL}/audio/load-warning.mp3`) : ({} as typeof Audio)
  );

  const { user } = useAuth0();

  const { canceledOrders } = useOrderStore();

  const orderIds = useMemo(() => lateLoadedDeliveries.map(({ id }) => id), [lateLoadedDeliveries]);

  const count = lateLoadedDeliveries.length;

  const handleCloseToast = useCallback(() => {
    if (closeToast) {
      // When the merchant dismisses the reminder pop-up
      trackSegment(EVENTS.BUTTON_CLICKED, {
        order_count: count,
        button_name: 'Manual Dismiss - Long Load Alert Screen',
        order_ids: orderIds,
        auth_id: user?.sub,
        button_clicked_at: Date.now(),
      });
      audio?.pause();
      closeToast();
    }
  }, [audio, closeToast, count, orderIds, user?.sub]);

  useEffect(() => {
    let count = repeatAudioCount;
    let timer: NodeJS.Timeout;
    const run = () => {
      if (count > 0) {
        audio?.play();
        count -= 1;
        timer = setTimeout(run, 12000); // wait 4 seconds before next audio clip plays (it plays for 8 seconds)
      }
    };

    run();

    return () => clearTimeout(timer);
  }, [audio, repeatAudioCount]);

  useEffect(() => {
    // When the merchant sees the load reminder popup
    trackSegment(EVENTS.PAGE_VIEWED, {
      order_count: count,
      order_ids: orderIds,
      page_name: 'Long Load Alert Screen',
      auth_id: user?.sub,
      page_viewed_at: Date.now(),
    });
  }, [count, orderIds, user?.sub]);

  useEffect(() => {
    // handle case where alert no longer needs to be shown if there are no more late loaded orders
    if (lateLoadedDeliveries.length === 0 && toast.isActive(LATE_LOAD_ID)) {
      console.log('toast -- dont show late since there are no more late loaded orders');
      toast.dismiss(LATE_LOAD_ID);
    }
  }, [count, lateLoadedDeliveries.length]);

  useEffect(() => {
    if (canceledOrders.length > 0 && toast.isActive(LATE_LOAD_ID)) {
      console.log('toast - dismissing late alert since canceled orders take higher priority');
      toast.dismiss(LATE_LOAD_ID);
    }
  }, [canceledOrders.length]);

  return (
    <Container onClick={handleCloseToast}>
      <Body>
        <Title className={Typography.HEADLINE_LG}>
          {ordersT(ORDERS_KEYS.ROBOT_WAITING_FOR, 'Coco Robot waiting for')}
        </Title>

        <CircleContainer>
          <Count className={Typography.DISPLAY_LG}>{count}</Count>
          <OrderCopy className={Typography.DISPLAY_MD}>
            {count === 1 ? ordersT(ORDERS_KEYS.ORDER, 'Order') : ordersT(ORDERS_KEYS.ORDERS, 'Orders')}
          </OrderCopy>
        </CircleContainer>

        <Caption className={Typography.HEADLINE_MD}>
          {lateLoadedDeliveries
            // Late loads from UE Marketplace with a bot serial will appear first
            .sort(({ deliveryMedium: a, orderSource: sourceA }, { deliveryMedium: b, orderSource: sourceB }) => {
              const hasBotSerialA = sourceA === Source.uber_eats_marketplace && a && hasBotSerial(a) && a.botSerial;
              const hasBotSerialB = sourceB === Source.uber_eats_marketplace && b && hasBotSerial(b) && b.botSerial;
              if (hasBotSerialA && !hasBotSerialB) return -1;
              if (!hasBotSerialA && hasBotSerialB) return 1;
              return 0;
            })
            .map(({ customerName, orderSource, deliveryMedium }) => {
              const displayName = customerName.length > 15 ? `${customerName.slice(0, 15)}...` : customerName;
              const botSerial =
                orderSource === Source.uber_eats_marketplace && deliveryMedium && hasBotSerial(deliveryMedium)
                  ? deliveryMedium.botSerial
                  : null;
              return `${displayName}${botSerial ? ` (${botSerial})` : ''}`;
            })
            .join(', ')}
        </Caption>

        <TapAnywhere className={Typography.HEADLINE_SM}>
          {commonT(COMMON_KEYS.TAP_ANYWHERE_TO_CLOSE, 'Tap anywhere to close')}
        </TapAnywhere>
      </Body>
    </Container>
  );
};
