import { addMilliseconds } from 'date-fns';
import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { toast } from 'react-toastify';

import { ListDelivery } from 'src/@types';
import { CANCELED_ID, LATE_LOAD_ID } from 'src/lib/toast';
import { LateLoadedOrder } from '../Alert';

interface Props {
  experiment: {
    lateOrderDisplayDurationMs: number;
    lateOrderDisplayDurationMins: number;
    lateOrderSnoozeTimeMs: number;
    repeatAudioCount: number;
  };
  lateLoadedDeliveries: ListDelivery[];
}

const LateOrder = ({ experiment, lateLoadedDeliveries }: Props) => {
  const { restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      // Check the condition before showing the toast since this is always mounted
      if (lateLoadedDeliveries.length > 0 && !toast.isActive(LATE_LOAD_ID)) {
        toast(
          <LateLoadedOrder
            repeatAudioCount={experiment.repeatAudioCount}
            lateLoadedDeliveries={lateLoadedDeliveries}
          />,
          {
            toastId: LATE_LOAD_ID,
            autoClose: experiment.lateOrderDisplayDurationMins * 60 * 1000,
          }
        );
      }
    },
  });

  useEffect(() => {
    toast.onChange(({ id, status }) => {
      if (status === 'removed' && id === LATE_LOAD_ID) {
        console.log('restarting late loaded orders timer', experiment.lateOrderSnoozeTimeMs);
        restart(addMilliseconds(new Date(), experiment.lateOrderSnoozeTimeMs));
      }
    });
  }, [experiment.lateOrderSnoozeTimeMs, restart]);

  useEffect(() => {
    toast.onChange(({ id, status }) => {
      if (status === 'removed' && id === CANCELED_ID) {
        console.log('canceled orders toast was just removed, reopen late loaded orders toast');
        restart(addMilliseconds(new Date(), 1000));
      }
    });
  }, [experiment.lateOrderSnoozeTimeMs, restart]);

  useEffect(() => {
    // Show toast and restart timer if the toast is currently not active and there are late loaded deliveries
    if (lateLoadedDeliveries.length > 0 && !toast.isActive(LATE_LOAD_ID)) restart(new Date());

    // Dismiss toast if the toast is currently active and there are no late loaded deliveries
    if (lateLoadedDeliveries.length == 0 && toast.isActive(LATE_LOAD_ID)) toast.dismiss(LATE_LOAD_ID);
  }, [lateLoadedDeliveries.length, restart]);

  return null;
};

export default LateOrder;
